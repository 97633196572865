<template>
  <div class="row">
    <card
      type="tasks"
      class="col-sm"
      style="height: auto; padding-bottom: 30px; overflow-x: auto"
    >
      <template slot="header">
        <div style="">
          <div class="row">
            <div class="">
              <h4 class="title d-inline">优惠券管理</h4>
            </div>
            <div class="ml-auto">
              <div>
                <base-button
                  style="margin-right: 10px"
                  size="sm"
                  type="danger"
                  @click="addIntegtal"
                  >添加优惠券</base-button
                >
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 20px">
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <el-table :data="queriedData">
              <el-table-column label="id" prop="id" :min-width="40">
              </el-table-column>
              <el-table-column label="优惠券名称" prop="name" :min-width="80">
              </el-table-column>
              <el-table-column
                label="抵扣描述"
                prop="deduction_desc"
                :min-width="80"
              >
              </el-table-column>
              <el-table-column
                label="抵扣价格(元)"
                prop="deduction_price"
                :min-width="60"
              >
              </el-table-column>
              <el-table-column
                label="优惠消耗积分(分)"
                prop="integral_required"
                :min-width="60"
              >
              </el-table-column>
              <el-table-column label="使用条件" prop="suit" :min-width="140">
              </el-table-column>
              <el-table-column
                label="有效期(天)"
                prop="valid_date"
                :min-width="60"
              >
              </el-table-column>
              <el-table-column
                label="有效期描述"
                prop="valid_date_desc"
                :min-width="60"
              >
              </el-table-column>
              <el-table-column
                label="添加时间"
                prop="add_time"
                :min-width="100"
                :formatter="timeFormatter"
              >
              </el-table-column>
              <el-table-column
                label="状态"
                prop="state"
                :min-width="60"
                align="center"
              >
                <template slot-scope="{ row }">
                  <base-button
                    v-if="row.state === 0"
                    style="margin-left: 6px"
                    size="sm"
                    round
                    icon
                    type="danger"
                    @click="changeIntegralState(row.id)"
                    ><i class="tim-icons icon-button-pause"></i
                  ></base-button>
                  <base-button
                    v-if="row.state === 1"
                    style="margin-left: 6px"
                    size="sm"
                    round
                    icon
                    type="success"
                    @click="changeIntegralState(row.id)"
                    ><i class="tim-icons icon-triangle-right-17"></i
                  ></base-button>
                </template>
              </el-table-column>

              <el-table-column label="包间编辑" prop="" :min-width="60">
                <template slot-scope="{ row }">
                  <base-button
                    type="success"
                    style="margin-left: 6px"
                    size="sm"
                    icon
                    @click="editIntegral(row.id)"
                  >
                    <i
                      style="color: #ffffff"
                      class="tim-icons icon-settings"
                    ></i>
                  </base-button>
                </template>
              </el-table-column>
              <!-- align="center" 需要表格居中加上这个属性-->
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                显示 {{ from + 1 }} 到 {{ to }} 总数 {{ total }} 条
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </div>

        <div>
          <modal
            modelWidth="600px"
            :show.sync="modals"
            headerClasses="justify-content-center"
            style="z-index: 1051"
          >
            <h4 slot="header" class="title title-up">添加优惠券</h4>
            <base-input
              label="优惠券名称"
              type="text"
              placeholder="请输入优惠券名称"
              v-model="name"
            ></base-input>
            <base-input
              label="抵扣描述  例：优惠券抵扣3元"
              type="text"
              placeholder="请输入抵扣描述"
              v-model="deduction_desc"
            ></base-input>
            <base-input
              label="抵扣价格  例：3"
              type="text"
              placeholder="请输入抵扣价格"
              v-model="deduction_price"
            ></base-input>
            <base-input
              label="有效期(天)"
              type="text"
              placeholder="请输入有效期"
              v-model="valid_date"
            ></base-input>
            <base-input
              label="有效期描述"
              type="text"
              placeholder="请输入有效期描述"
              v-model="valid_date_desc"
            ></base-input>
            <base-input
              label="兑换所需积分  例：300"
              type="text"
              placeholder="请输入所需积分"
              v-model="integral_required"
            ></base-input>
            <base-input
              label="使用条件"
              type="text"
              placeholder="请输入使用条件"
              v-model="suit"
            ></base-input>

            <div style="margin-top: 18px"></div>
            <template slot="footer">
              <base-button @click="doAddIntegtal">确定</base-button>
              <base-button type="danger" @click.native="modals = false"
                >取消
              </base-button>
            </template>
          </modal>
        </div>

        <div>
          <modal
            modelWidth="600px"
            :show.sync="modals1"
            headerClasses="justify-content-center"
            style="z-index: 1051"
          >
            <h4 slot="header" class="title title-up">编辑优惠券</h4>
            <base-input
              label="优惠券名称"
              type="text"
              placeholder="请输入优惠券名称"
              v-model="editname"
            ></base-input>
            <base-input
              label="抵扣描述  例:优惠券抵扣3元"
              type="text"
              placeholder="请输入抵扣描述"
              v-model="editdeduction_desc"
            ></base-input>
            <base-input
              label="抵扣价格  例:3"
              type="text"
              placeholder="请输入抵扣价格"
              v-model="editdeduction_price"
            ></base-input>
            <base-input
              label="有效期(天)"
              type="text"
              placeholder="请输入有效期"
              v-model="editvalid_date"
            ></base-input>
            <base-input
              label="有效期描述"
              type="text"
              placeholder="请输入有效期描述"
              v-model="editvalid_date_desc"
            ></base-input>
            <base-input
              label="兑换所需积分  例:300"
              type="text"
              placeholder="请输入所需积分"
              v-model="editintegral_required"
            ></base-input>
            <base-input
              label="使用条件"
              type="text"
              placeholder="请输入使用条件"
              v-model="editsuit"
            ></base-input>

            <div style="margin-top: 18px"></div>
            <template slot="footer">
              <base-button @click="doEditIntegral">确定</base-button>
              <base-button type="danger" @click.native="modals1 = false"
                >取消
              </base-button>
            </template>
          </modal>
        </div>

        
      </template>
    </card>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { BasePagination } from "../../components";
import Swal from "sweetalert2";
import { http } from "../../util/http.js";
import { changeDateFormat, checkTime } from "../../util/utils.js";
import Badge from "@/components/Badge";
import Modal from "@/components/Modal";
import BaseTable from "@/components/BaseTable";
export default {
  components: {
    // Badge,
    Modal,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  computed: {
    queriedData() {
      return this.tableData;
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.pagination.total;
    },
  },
  data() {
    return {
      checkTime,
      changeDateFormat,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      blurredSearchQuery: "",
      propsToSearch: ["cdk", "email", "age"],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      modals: false,
      modals1: false,

      name: "满减优惠券",
      deduction_price: "",
      deduction_desc: "",
      valid_date: "90",
      valid_date_desc: "3个月",
      integral_required: "",
      suit: "麻小虎任意门店预约或者续单下单时无门槛优惠",

      editname: "",
      editdeduction_price: "",
      editdeduction_desc: "",
      editvalid_date: "",
      editvalid_date_desc: "",
      editintegral_required: "",
      editsuit: "",

      integralId:"",
    };
  },
  methods: {
    onBlur() {
      // 当输入框失去焦点时，改变 blurredSearchQuery 的值
      this.blurredSearchQuery = this.searchQuery;
    },
    // 编辑优惠券
    editIntegral(integralId){
       http
        .post(
          "/v1/admin/getIntegral",
          {
          integralId :integralId,
          },
          { headers: { adminToken: localStorage.getItem("adminToken") } }
        )
        .then((res) => {
          if (res.data.code != 200) {
            this.$notify({
              type: "danger",
              message: res.data.message,
              timeout: 2000,
              icon: "tim-icons icon-bell-55",
            });
            return;
          }
          this.editname = res.data.data.integralInfo.name
          this.editdeduction_price = res.data.data.integralInfo.deduction_price
          this.editdeduction_desc = res.data.data.integralInfo.deduction_desc
          this.editvalid_date = res.data.data.integralInfo.valid_date
          this.editvalid_date_desc = res.data.data.integralInfo.valid_date_desc
          this.editintegral_required = res.data.data.integralInfo.integral_required
          this.editsuit = res.data.data.integralInfo.suit
        })
        .catch((error) => {
          console.error("发生错误:", error);
        });
        this.modals1 = true
        this.integralId = integralId
    },
    // 执行编辑优惠券
    doEditIntegral(){
      http
        .post(
          "/v1/admin/updateIntegral",
          {
            name: this.editname,
            deduction_price: this.editdeduction_price,
            deduction_desc: this.editdeduction_desc,
            valid_date: this.editvalid_date,
            valid_date_desc: this.editvalid_date_desc,
            integral_required: this.editintegral_required,
            suit: this.editsuit,
            integralId :this.integralId,
          },
          { headers: { adminToken: localStorage.getItem("adminToken") } }
        )
        .then((res) => {
          if (res.data.code != 200) {
            Swal.fire({
              icon: "error",
              title: "编辑失败",
              text: res.data.message,
              customClass: {
                confirmButton: "btn btn-success btn-fill",
              },
              buttonsStyling: false,
            });
            return;
          }
          this.handleCurrentChange();
          Swal.fire({
            icon: "success",
            title: "编辑成功",
            text: res.data.message,
            customClass: {
              confirmButton: "btn btn-success btn-fill",
            },
            buttonsStyling: false,
          });

          this.modals1 = false;
        })
        .catch((error) => {
          console.error("发生错误:", error);
          // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
        });
    },
    // 更改优惠券状态
    changeIntegralState(id) {
      http
        .post(
          "/v1/admin/changeIntegral",
          {
            integralId: id,
          },
          { headers: { adminToken: localStorage.getItem("adminToken") } }
        )
        .then((res) => {
          if (res.data.code != 200) {
            Swal.fire({
              icon: "error",
              title: "更改失败",
              text: res.data.message,
              customClass: {
                confirmButton: "btn btn-success btn-fill",
              },
              buttonsStyling: false,
            });
            return;
          }
          this.handleCurrentChange();
          Swal.fire({
            icon: "success",
            title: "更改成功",
            text: res.data.message,
            customClass: {
              confirmButton: "btn btn-success btn-fill",
            },
            buttonsStyling: false,
          });
        })
        .catch((error) => {
          console.error("发生错误:", error);
          // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
        });
    },
    // 执行添加优惠券
    doAddIntegtal() {
      http
        .post(
          "/v1/admin/addIntegral",
          {
            name: this.name,
            deduction_price: this.deduction_price,
            deduction_desc: this.deduction_desc,
            valid_date: this.valid_date,
            valid_date_desc: this.valid_date_desc,
            integral_required: this.integral_required,
            suit: this.suit,
          },
          { headers: { adminToken: localStorage.getItem("adminToken") } }
        )
        .then((res) => {
          if (res.data.code != 200) {
            Swal.fire({
              icon: "error",
              title: "添加失败",
              text: res.data.message,
              customClass: {
                confirmButton: "btn btn-success btn-fill",
              },
              buttonsStyling: false,
            });
            return;
          }
          this.handleCurrentChange();
          Swal.fire({
            icon: "success",
            title: "添加成功",
            text: res.data.message,
            customClass: {
              confirmButton: "btn btn-success btn-fill",
            },
            buttonsStyling: false,
          });

          this.modals = false;
        })
        .catch((error) => {
          console.error("发生错误:", error);
          // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
        });
    },
    // 添加优惠券
    addIntegtal() {
      this.modals = true;
    },
    hangleCancel(id) {
      http
        .post(
          "/v1/admin/cancelIntegral",
          {
            integralId: id,
          },
          { headers: { adminToken: localStorage.getItem("adminToken") } }
        )
        .then((res) => {
          if (res.data.code != 200) {
            Swal.fire({
              icon: "error",
              title: "停用优惠券失败",
              text: res.data.message,
              customClass: {
                confirmButton: "btn btn-success btn-fill",
              },
              buttonsStyling: false,
            });
            return;
          }
          this.handleCurrentChange();
          Swal.fire({
            icon: "success",
            title: "停用优惠券成功",
            text: res.data.message,
            customClass: {
              confirmButton: "btn btn-success btn-fill",
            },
            buttonsStyling: false,
          });
        })
        .catch((error) => {
          console.error("发生错误:", error);
          // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
        });
    },
    // 获取所有用户信息
    handleCurrentChange() {
      http
        .post(
          "/v1/admin/getAllIntegral",
          {
            currentPage: this.pagination.currentPage,
            perPage: this.pagination.perPage,
            searchQuery: this.searchQuery,
          },
          { headers: { adminToken: localStorage.getItem("adminToken") } }
        )
        .then((res) => {
          if (res.data.code != 200) {
            this.$notify({
              type: "danger",
              message: res.data.message,
              timeout: 2000,
              icon: "tim-icons icon-bell-55",
            });
            return;
          }

          this.pagination.total = res.data.data.total;
          this.tableData = res.data.data.integral;
        })
        .catch((error) => {
          console.error("发生错误:", error);
        });
    },
    timeFormatter(row, column, cellValue) {
      // 在这里处理你的数据
      // row 是整个行数据
      // column 是列数据
      // cellValue 是单元格的值
      // 例如你想将名字转为大写形式：
      if (cellValue == "0001-01-01T00:00:00Z") {
        return "";
      } else {
        cellValue = changeDateFormat(cellValue);
        return cellValue;
      }
    },
  },
  mounted() {
    this.handleCurrentChange();
  },
  watch: {
    blurredSearchQuery(newVal, oldVal) {
      // 监控搜索
      if (newVal == "") {
        this.handleCurrentChange();
        return;
      }
      this.handleCurrentChange();
    },
    "pagination.currentPage"(newPage, oldPage) {
      // 监控翻页
      // 这里进行你的数据请求等操作
      this.handleCurrentChange();
    },
    "pagination.perPage"(newPage, oldPage) {
      // 监控翻页
      // 这里进行你的数据请求等操作
      this.handleCurrentChange();
    },
  },
};
</script>
<style scoped lang="scss" >
.pagination-select,
.search-input {
  width: 200px;
}
::v-deep .modal-dialog {
  //max-width: 2200px !important;
  margin: 5.2rem auto !important;
}
</style>
