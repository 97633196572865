<template>
  <div class="row">
    <card
      type="tasks"
      class="col-sm"
      style="height: auto; padding-bottom: 30px; overflow-x: auto"
    >
      <template slot="header">
        <div style="">
          <div class="row">
            <div class="">
              <h4 class="title d-inline">余额套餐管理</h4>
            </div>
            <div class="ml-auto">
              <div>
                <base-button
                  style="margin-right: 10px"
                  size="sm"
                  type="danger"
                  @click="addBalance"
                  >添加余额套餐</base-button
                >
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 20px">
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <el-table :data="queriedData">
              <el-table-column label="id" prop="id" :min-width="40">
              </el-table-column>
              <el-table-column
                label="充值支付金额"
                prop="price"
                :min-width="80"
              >
              </el-table-column>
              <el-table-column
                label="实际到账金额"
                prop="actual_amount"
                :min-width="80"
              >
              </el-table-column>
              <el-table-column
                label="状态"
                prop="state"
                :min-width="60"
                align="center"
              >
                <template slot-scope="{ row }">
                  <base-button
                    v-if="row.state === 0"
                    style="margin-left: 6px"
                    size="sm"
                    round
                    icon
                    type="danger"
                    @click="changeBalanceState(row.id)"
                    ><i class="tim-icons icon-button-pause"></i
                  ></base-button>
                  <base-button
                    v-if="row.state === 1"
                    style="margin-left: 6px"
                    size="sm"
                    round
                    icon
                    type="success"
                    @click="changeBalanceState(row.id)"
                    ><i class="tim-icons icon-triangle-right-17"></i
                  ></base-button>
                </template>
              </el-table-column>

              <el-table-column label="包间编辑" prop="" :min-width="60">
                <template slot-scope="{ row }">
                  <base-button
                    type="success"
                    style="margin-left: 6px"
                    size="sm"
                    icon
                    @click="editBalance(row.id)"
                  >
                    <i
                      style="color: #ffffff"
                      class="tim-icons icon-settings"
                    ></i>
                  </base-button>
                </template>
              </el-table-column>
              <!-- align="center" 需要表格居中加上这个属性-->
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                显示 {{ from + 1 }} 到 {{ to }} 总数 {{ total }} 条
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </div>

        <div>
          <modal
            modelWidth="600px"
            :show.sync="modals"
            headerClasses="justify-content-center"
            style="z-index: 1051"
          >
            <h4 slot="header" class="title title-up">添加余额套餐</h4>
            <base-input
              label="充值金额(元)"
              type="text"
              placeholder="请输入充值金额"
              v-model="price"
            ></base-input>
            <base-input
              label="实际到账(元)"
              type="text"
              placeholder="请输入实际到账金额"
              v-model="actual_amount"
            ></base-input>
            <div style="margin-top: 18px"></div>
            <template slot="footer">
              <base-button @click="doAddBalance">确定</base-button>
              <base-button type="danger" @click.native="modals = false"
                >取消
              </base-button>
            </template>
          </modal>
        </div>

        <div>
          <modal
            modelWidth="600px"
            :show.sync="modals1"
            headerClasses="justify-content-center"
            style="z-index: 1051"
          >
            <h4 slot="header" class="title title-up">添加余额套餐</h4>
            <base-input
              label="充值金额(元)"
              type="text"
              placeholder="请输入充值金额"
              v-model="editprice"
            ></base-input>
            <base-input
              label="实际到账(元)"
              type="text"
              placeholder="请输入实际到账金额"
              v-model="editactual_amount"
            ></base-input>
            <div style="margin-top: 18px"></div>
            <template slot="footer">
              <base-button @click="doEditBalance">确定</base-button>
              <base-button type="danger" @click.native="modals1 = false"
                >取消
              </base-button>
            </template>
          </modal>
        </div>
      </template>
    </card>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { BasePagination } from "../../components";
import Swal from "sweetalert2";
import { http } from "../../util/http.js";
import { changeDateFormat, checkTime } from "../../util/utils.js";
import Badge from "@/components/Badge";
import Modal from "@/components/Modal";
import BaseTable from "@/components/BaseTable";
export default {
  components: {
    // Badge,
    Modal,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  computed: {
    queriedData() {
      return this.tableData;
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.pagination.total;
    },
  },
  data() {
    return {
      checkTime,
      changeDateFormat,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      blurredSearchQuery: "",
      propsToSearch: ["cdk", "email", "age"],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      modals: false,
      modals1: false,

      price: "",
      actual_amount: "",

      editprice: "",
      editactual_amount: "",

      balanceId: "",
    };
  },
  methods: {
    onBlur() {
      // 当输入框失去焦点时，改变 blurredSearchQuery 的值
      this.blurredSearchQuery = this.searchQuery;
    },
    // 编辑余额套餐
    editBalance(balanceId) {
      http
        .post(
          "/v1/admin/getBalanceInfo",
          {
            balanceId: balanceId,
          },
          { headers: { adminToken: localStorage.getItem("adminToken") } }
        )
        .then((res) => {
          if (res.data.code != 200) {
            this.$notify({
              type: "danger",
              message: res.data.message,
              timeout: 2000,
              icon: "tim-icons icon-bell-55",
            });
            return;
          }

          this.editprice = res.data.data.balance.price;
          this.editactual_amount = res.data.data.balance.actual_amount;
        })
        .catch((error) => {
          console.error("发生错误:", error);
        });
      this.modals1 = true;
      this.balanceId = balanceId;
    },
    // 执行编辑余额套餐
    doEditBalance() {
      http
        .post(
          "/v1/admin/updateBalance",
          {
            price: this.editprice,
            actual_amount: this.editactual_amount,
            balanceId: this.balanceId,
          },
          { headers: { adminToken: localStorage.getItem("adminToken") } }
        )
        .then((res) => {
          if (res.data.code != 200) {
            Swal.fire({
              icon: "error",
              title: "编辑失败",
              text: res.data.message,
              customClass: {
                confirmButton: "btn btn-success btn-fill",
              },
              buttonsStyling: false,
            });
            return;
          }
          this.handleCurrentChange();
          Swal.fire({
            icon: "success",
            title: "编辑成功",
            text: res.data.message,
            customClass: {
              confirmButton: "btn btn-success btn-fill",
            },
            buttonsStyling: false,
          });

          this.modals1 = false;
        })
        .catch((error) => {
          console.error("发生错误:", error);
          // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
        });
    },
    // 更改优惠券状态
    changeBalanceState(id) {
      http
        .post(
          "/v1/admin/changeBalanceState",
          {
            balanceId: id,
          },
          { headers: { adminToken: localStorage.getItem("adminToken") } }
        )
        .then((res) => {
          if (res.data.code != 200) {
            Swal.fire({
              icon: "error",
              title: "更改失败",
              text: res.data.message,
              customClass: {
                confirmButton: "btn btn-success btn-fill",
              },
              buttonsStyling: false,
            });
            return;
          }
          this.handleCurrentChange();
          Swal.fire({
            icon: "success",
            title: "更改成功",
            text: res.data.message,
            customClass: {
              confirmButton: "btn btn-success btn-fill",
            },
            buttonsStyling: false,
          });
        })
        .catch((error) => {
          console.error("发生错误:", error);
          // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
        });
    },
    // 执行添加余额套餐
    doAddBalance() {
      http
        .post(
          "/v1/admin/addBalance",
          {
            price: this.price,
            actual_amount: this.actual_amount,
          },
          { headers: { adminToken: localStorage.getItem("adminToken") } }
        )
        .then((res) => {
          if (res.data.code != 200) {
            Swal.fire({
              icon: "error",
              title: "添加失败",
              text: res.data.message,
              customClass: {
                confirmButton: "btn btn-success btn-fill",
              },
              buttonsStyling: false,
            });
            return;
          }
          this.handleCurrentChange();
          Swal.fire({
            icon: "success",
            title: "添加成功",
            text: res.data.message,
            customClass: {
              confirmButton: "btn btn-success btn-fill",
            },
            buttonsStyling: false,
          });

          this.modals = false;
        })
        .catch((error) => {
          console.error("发生错误:", error);
          // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
        });
    },
    // 添加余额套餐
    addBalance() {
      this.modals = true;
    },
    hangleCancel(id) {
      http
        .post(
          "/v1/admin/cancelIntegral",
          {
            integralId: id,
          },
          { headers: { adminToken: localStorage.getItem("adminToken") } }
        )
        .then((res) => {
          if (res.data.code != 200) {
            Swal.fire({
              icon: "error",
              title: "停用优惠券失败",
              text: res.data.message,
              customClass: {
                confirmButton: "btn btn-success btn-fill",
              },
              buttonsStyling: false,
            });
            return;
          }
          this.handleCurrentChange();
          Swal.fire({
            icon: "success",
            title: "停用优惠券成功",
            text: res.data.message,
            customClass: {
              confirmButton: "btn btn-success btn-fill",
            },
            buttonsStyling: false,
          });
        })
        .catch((error) => {
          console.error("发生错误:", error);
          // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
        });
    },
    // 获取所有用户信息
    handleCurrentChange() {
      http
        .post(
          "/v1/admin/getAllBalance",
          {
            currentPage: this.pagination.currentPage,
            perPage: this.pagination.perPage,
            searchQuery: this.searchQuery,
          },
          { headers: { adminToken: localStorage.getItem("adminToken") } }
        )
        .then((res) => {
          if (res.data.code != 200) {
            this.$notify({
              type: "danger",
              message: res.data.message,
              timeout: 2000,
              icon: "tim-icons icon-bell-55",
            });
            return;
          }

          this.pagination.total = res.data.data.total;
          this.tableData = res.data.data.balance;
        })
        .catch((error) => {
          console.error("发生错误:", error);
        });
    },
    timeFormatter(row, column, cellValue) {
      // 在这里处理你的数据
      // row 是整个行数据
      // column 是列数据
      // cellValue 是单元格的值
      // 例如你想将名字转为大写形式：
      if (cellValue == "0001-01-01T00:00:00Z") {
        return "";
      } else {
        cellValue = changeDateFormat(cellValue);
        return cellValue;
      }
    },
  },
  mounted() {
    this.handleCurrentChange();
  },
  watch: {
    blurredSearchQuery(newVal, oldVal) {
      // 监控搜索
      if (newVal == "") {
        this.handleCurrentChange();
        return;
      }
      this.handleCurrentChange();
    },
    "pagination.currentPage"(newPage, oldPage) {
      // 监控翻页
      // 这里进行你的数据请求等操作
      this.handleCurrentChange();
    },
    "pagination.perPage"(newPage, oldPage) {
      // 监控翻页
      // 这里进行你的数据请求等操作
      this.handleCurrentChange();
    },
  },
};
</script>
<style scoped lang="scss" >
.pagination-select,
.search-input {
  width: 200px;
}
::v-deep .modal-dialog {
  //max-width: 2200px !important;
  margin: 5.2rem auto !important;
}
</style>
