<template>
  <div class="row">
    <card
      type="tasks"
      class="col-sm"
      style="height: auto; padding-bottom: 30px; overflow-x: auto"
    >
      <template slot="header">
        <div style="">
          <div class="row">
            <div class="">
              <h4 class="title d-inline">团购券管理</h4>
            </div>
            <div class="ml-auto">
              <div>
                <base-button
                  style="margin-right: 10px"
                  size="sm"
                  type="danger"
                  @click="addTuanGou"
                  >添加团购券</base-button
                >
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 20px">
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div>
                <el-select
                  class="select-danger"
                  style="width: 260px"
                  placeholder="请选择"
                  v-model="tuanGouSelects.simple"
                  @change="handleSelectsChange"
                >
                  <el-option
                    v-for="option in tuanGouSelects.languages"
                    class="select-danger"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
                </el-select>
                <el-select
                  class="select-primary mb-3 pagination-select"
                  v-model="pagination.perPage"
                  placeholder="Per page"
                  style="margin-left: 10px"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>

              <base-input>
                <el-input
                  @blur="onBlur"
                  type="search"
                  class="mb-3 search-input"
                  prefix-icon="el-icon-search"
                  placeholder="搜索店铺名称"
                  v-model="searchQuery"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>
            <el-table :data="queriedData">
              <el-table-column label="id" prop="id" :min-width="70">
              </el-table-column>
              <el-table-column label="团购券名称" prop="name" :min-width="150">
              </el-table-column>
              <el-table-column
                label="对应店铺"
                prop="store_name"
                :min-width="150"
              >
              </el-table-column>
              <el-table-column
                label="美团团购id"
                prop="dealgroup_id"
                :min-width="110"
              >
              </el-table-column>
              <el-table-column
                label="团购券抵扣描述"
                prop="coupon_desc"
                :min-width="110"
              >
              </el-table-column>
              <el-table-column
                label="原价(元)"
                prop="original_price"
                :min-width="90"
              >
              </el-table-column>
              <el-table-column label="现价(元)" prop="price" :min-width="90">
                <template slot-scope="{ row }">
                  <badge type="danger">{{ row.price }}</badge>
                </template>
              </el-table-column>
              <el-table-column
                label="对应包间类型"
                prop="room_type"
                :min-width="100"
              >
                <template slot-scope="{ row }">
                  <badge type="info" v-if="row.room_type == 0">小包</badge>
                  <badge type="info" v-if="row.room_type == 1">中包</badge>
                  <badge type="info" v-if="row.room_type == 2">大包</badge>
                  <badge type="info" v-if="row.room_type == 3">任意包</badge>
                </template>
              </el-table-column>
              <el-table-column
                label="抵扣套餐名称"
                prop="package_desc"
                :min-width="100"
              >
              </el-table-column>
              <!-- <el-table-column
                label="抵扣套餐价格"
                prop="package_price"
                :min-width="100"
              >
              </el-table-column> -->
              <el-table-column
                label="有效期(天)"
                prop="valid_date"
                :min-width="100"
              >
              </el-table-column>
              <el-table-column
                label="有效期描述"
                prop="valid_date_desc"
                :min-width="110"
              >
              </el-table-column>
              <el-table-column label="团购券类型" prop="type" :min-width="160">
                <template slot-scope="{ row }">
                  <badge type="warning" v-if="row.type == 0">任意时间</badge>
                  <badge type="warning" v-if="row.type == 1">工作日</badge>
                  <badge type="warning" v-if="row.type == 2">周末</badge>
                  <badge type="warning" v-if="row.type == 3"
                    >工作日(闲时:08~18)</badge
                  >
                  <badge type="warning" v-if="row.type == 4"
                    >工作日(通宵:00~10)</badge
                  >
                  <badge type="warning" v-if="row.type == 5"
                    >周末(通宵:00~10)</badge
                  >
                </template>
              </el-table-column>
              <el-table-column
                label="购买类型"
                prop="pay_type"
                :min-width="100"
              >
                <template slot-scope="{ row }">
                  <badge type="default" v-if="row.pay_type == 0">小程序</badge>
                  <badge type="default" v-if="row.pay_type == 1">美团</badge>
                  <badge type="default" v-if="row.pay_type == 2">抖音</badge>
                </template>
              </el-table-column>
              <el-table-column
                label="团购券规则描述"
                prop="use_rule_desc"
                :min-width="150"
              >
              </el-table-column>
              <el-table-column
                label="添加时间"
                prop="add_time"
                :min-width="130"
                :formatter="timeFormatter"
              >
              </el-table-column>

              <el-table-column
                label="状态"
                prop="state"
                :min-width="110"
                align="center"
              >
                <template slot-scope="{ row }">
                  <base-button
                    v-if="row.state === 0"
                    style="margin-left: 6px"
                    size="sm"
                    round
                    icon
                    type="danger"
                    @click="changeTuanGouState(row.id)"
                    ><i class="tim-icons icon-button-pause"></i
                  ></base-button>
                  <base-button
                    v-if="row.state === 1"
                    style="margin-left: 6px"
                    size="sm"
                    round
                    icon
                    type="success"
                    @click="changeTuanGouState(row.id)"
                    ><i class="tim-icons icon-triangle-right-17"></i
                  ></base-button>
                </template>
              </el-table-column>

              <el-table-column label="团购券编辑" prop="" :min-width="110">
                <template slot-scope="{ row }">
                  <base-button
                    type="success"
                    style="margin-left: 6px"
                    size="sm"
                    icon
                    @click="editTuan(row.id)"
                  >
                    <i
                      style="color: #ffffff"
                      class="tim-icons icon-settings"
                    ></i>
                  </base-button>
                </template>
              </el-table-column>
              <!-- align="center" 需要表格居中加上这个属性-->
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                显示 {{ from + 1 }} 到 {{ to }} 总数 {{ total }} 条
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </div>

        <div>
          <modal
            modelWidth="600px"
            :show.sync="modals"
            headerClasses="justify-content-center"
            style="z-index: 1051"
          >
            <h4 slot="header" class="title title-up">添加团购券</h4>
            <base-input
              label="团购券名称 例：大包劵5小时 (任意时段)"
              type="text"
              placeholder="请输入团购券名称"
              v-model="name"
            ></base-input>
            <div>选择门店</div>
            <div style="padding-bottom: 10px">
              <el-select
                class="select-danger"
                placeholder="请选择门店"
                v-model="selects.simple"
                style="margin-top: 8px; width: 100%"
              >
                <el-option
                  v-for="option in selects.languages"
                  class="select-danger"
                  :value="option.value"
                  :label="option.label"
                  :key="option.value"
                >
                </el-option>
              </el-select>
            </div>
            <div>选择包间类型</div>
            <div style="padding-bottom: 10px">
              <el-select
                class="select-danger"
                placeholder="请选择包间类型"
                v-model="roomSelects.simple"
                style="margin-top: 8px; width: 100%"
              >
                <el-option
                  v-for="option in roomSelects.languages"
                  class="select-danger"
                  :value="option.value"
                  :label="option.label"
                  :key="option.value"
                >
                </el-option>
              </el-select>
            </div>
            <div>选择团购抵扣套餐</div>
            <div style="padding-bottom: 10px">
              <el-select
                class="select-danger"
                placeholder="请选择团购抵扣套餐"
                v-model="packageSelects.simple"
                style="margin-top: 8px; width: 100%"
              >
                <el-option
                  v-for="option in packageSelects.languages"
                  class="select-danger"
                  :value="option.value"
                  :label="option.label"
                  :key="option.value"
                >
                </el-option>
              </el-select>
            </div>

            <div>选择购买类型</div>
            <div style="padding-bottom: 10px">
              <el-select
                class="select-danger"
                placeholder="请选择购买类型"
                v-model="payTypeSelects.simple"
                style="margin-top: 8px; width: 100%"
              >
                <el-option
                  v-for="option in payTypeSelects.languages"
                  class="select-danger"
                  :value="option.value"
                  :label="option.label"
                  :key="option.value"
                >
                </el-option>
              </el-select>
            </div>

            <div>选择团购券使用类型</div>
            <div style="padding-bottom: 10px">
              <el-select
                class="select-danger"
                placeholder="请选择团购券使用类型"
                v-model="typeSelects.simple"
                style="margin-top: 8px; width: 100%"
              >
                <el-option
                  v-for="option in typeSelects.languages"
                  class="select-danger"
                  :value="option.value"
                  :label="option.label"
                  :key="option.value"
                >
                </el-option>
              </el-select>
            </div>

            <base-input
              label="团购券抵扣描述  例：团购券抵扣5小时"
              type="text"
              placeholder="请输入团购券抵扣描述"
              v-model="coupon_desc"
            ></base-input>
            <base-input
              label="美团团购套餐id"
              type="text"
              placeholder="请输入美团团购套餐id"
              v-model="dealgroup_id"
            ></base-input>
            <base-input
              label="原价(元)  例：168"
              type="text"
              placeholder="请输入原价"
              v-model="original_price"
            ></base-input>
            <base-input
              label="现价(元) 例：58"
              type="text"
              placeholder="请输入现价"
              v-model="price"
            ></base-input>
            <base-input
              label="有效期(天)"
              type="text"
              placeholder="请输入有效期"
              v-model="valid_date"
            ></base-input>
            <base-input
              label="有效期描述"
              type="text"
              placeholder="请输入有效期描述"
              v-model="valid_date_desc"
            ></base-input>

            <base-input label="团购券规则描述：">
              <textarea
                ref="myTextarea"
                v-model="use_rule_desc"
                style="padding: 0"
                class="form-control"
                id="exampleFormControlTextarea1"
                placeholder="请输入团购券规则描述"
                rows="3"
              ></textarea>
            </base-input>

            <div style="margin-top: 18px"></div>
            <template slot="footer">
              <base-button @click="doAddTuanGou">确定</base-button>
              <base-button type="danger" @click.native="modals = false"
                >取消
              </base-button>
            </template>
          </modal>
        </div>

        <div>
          <modal
            modelWidth="600px"
            :show.sync="modals1"
            headerClasses="justify-content-center"
            style="z-index: 1051"
          >
            <h4 slot="header" class="title title-up">编辑团购券</h4>
            <base-input
              label="团购券名称 例：大包劵5小时 (任意时段)"
              type="text"
              placeholder="请输入团购券名称"
              v-model="eidtname"
            ></base-input>
            <div>选择门店</div>
            <div style="padding-bottom: 10px">
              <el-select
                class="select-danger"
                placeholder="请选择门店"
                v-model="eidtselects.simple"
                style="margin-top: 8px; width: 100%"
              >
                <el-option
                  v-for="option in eidtselects.languages"
                  class="select-danger"
                  :value="option.value"
                  :label="option.label"
                  :key="option.value"
                >
                </el-option>
              </el-select>
            </div>
            <div>选择包间类型</div>
            <div style="padding-bottom: 10px">
              <el-select
                class="select-danger"
                placeholder="请选择包间类型"
                v-model="eidtroomSelects.simple"
                style="margin-top: 8px; width: 100%"
              >
                <el-option
                  v-for="option in eidtroomSelects.languages"
                  class="select-danger"
                  :value="option.value"
                  :label="option.label"
                  :key="option.value"
                >
                </el-option>
              </el-select>
            </div>
            <div>选择团购抵扣套餐</div>
            <div style="padding-bottom: 10px">
              <el-select
                class="select-danger"
                placeholder="请选择团购抵扣套餐"
                v-model="eidtpackageSelects.simple"
                style="margin-top: 8px; width: 100%"
              >
                <el-option
                  v-for="option in eidtpackageSelects.languages"
                  class="select-danger"
                  :value="option.value"
                  :label="option.label"
                  :key="option.value"
                >
                </el-option>
              </el-select>
            </div>

            <div>选择购买类型</div>
            <div style="padding-bottom: 10px">
              <el-select
                class="select-danger"
                placeholder="请选择购买类型"
                v-model="eidtpayTypeSelects.simple"
                style="margin-top: 8px; width: 100%"
              >
                <el-option
                  v-for="option in eidtpayTypeSelects.languages"
                  class="select-danger"
                  :value="option.value"
                  :label="option.label"
                  :key="option.value"
                >
                </el-option>
              </el-select>
            </div>

            <div>选择团购券使用类型</div>
            <div style="padding-bottom: 10px">
              <el-select
                class="select-danger"
                placeholder="请选择团购券使用类型"
                v-model="eidttypeSelects.simple"
                style="margin-top: 8px; width: 100%"
              >
                <el-option
                  v-for="option in eidttypeSelects.languages"
                  class="select-danger"
                  :value="option.value"
                  :label="option.label"
                  :key="option.value"
                >
                </el-option>
              </el-select>
            </div>

            <base-input
              label="美团团购套餐id"
              type="text"
              placeholder="请输入美团团购套餐id"
              v-model="eidtdealgroup_id"
            ></base-input>
            <base-input
              label="团购券抵扣描述  例：团购券抵扣5小时"
              type="text"
              placeholder="请输入团购券抵扣描述"
              v-model="eidtcoupon_desc"
            ></base-input>
            <base-input
              label="原价(元)  例：168"
              type="text"
              placeholder="请输入原价"
              v-model="eidtoriginal_price"
            ></base-input>
            <base-input
              label="现价(元) 例：58"
              type="text"
              placeholder="请输入现价"
              v-model="eidtprice"
            ></base-input>
            <base-input
              label="有效期(天)"
              type="text"
              placeholder="请输入有效期"
              v-model="eidtvalid_date"
            ></base-input>
            <base-input
              label="有效期描述"
              type="text"
              placeholder="请输入有效期描述"
              v-model="eidtvalid_date_desc"
            ></base-input>

            <base-input label="团购券规则描述：">
              <textarea
                ref="myTextarea"
                v-model="eidtuse_rule_desc"
                style="padding: 0"
                class="form-control"
                id="exampleFormControlTextarea1"
                placeholder="请输入团购券规则描述"
                rows="3"
              ></textarea>
            </base-input>

            <div style="margin-top: 18px"></div>
            <template slot="footer">
              <base-button @click="doEditIntegral">确定</base-button>
              <base-button type="danger" @click.native="modals1 = false"
                >取消
              </base-button>
            </template>
          </modal>
        </div>
      </template>
    </card>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { BasePagination } from "../../components";
import Swal from "sweetalert2";
import { http } from "../../util/http.js";
import { changeDateFormat, checkTime } from "../../util/utils.js";
import Badge from "@/components/Badge";
import Modal from "@/components/Modal";
import BaseTable from "@/components/BaseTable";
export default {
  components: {
    Badge,
    Modal,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  computed: {
    queriedData() {
      return this.tableData;
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.pagination.total;
    },
  },
  data() {
    return {
      tuanGouSelects: {
        simple: "0",
        languages: [{ value: "0", label: "全部门店" }],
      },
      checkTime,
      changeDateFormat,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      blurredSearchQuery: "",
      propsToSearch: ["cdk", "email", "age"],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      modals: false,
      modals1: false,

      tuanGouId: "",

      // 选择门店
      selects: {
        simple: "",
        languages: [],
      },
      // 团购抵扣套餐
      packageSelects: {
        simple: "",
        languages: [],
      },
      // 包间类型
      roomSelects: {
        simple: "",
        languages: [
          { value: 0, label: "小包" },
          { value: 1, label: "中包" },
          { value: 2, label: "大包" },
          { value: 3, label: "任意包" },
        ],
      },
      // 购买类型
      payTypeSelects: {
        simple: "",
        languages: [
          { value: 0, label: "小程序" },
          { value: 1, label: "美团" },
          { value: 2, label: "抖音" },
        ],
      },
      // 团购券使用类型
      typeSelects: {
        simple: "",
        languages: [
          { value: 0, label: "任意时间" },
          { value: 1, label: "工作日(任意时间)" },
          { value: 2, label: "周末(任意时间)" },
          { value: 3, label: "工作日(闲时:08:00~18:00)" },
          { value: 4, label: "工作日(通宵:00:00~10:00)" },
          { value: 5, label: "周末(通宵:00:00~10:00)" },
        ],
      },
      name: "",
      coupon_desc: "",
      original_price: "",
      price: "",
      valid_date: "30",
      valid_date_desc: "购买后30天有效",
      use_rule_desc: "",
      dealgroup_id: "",

      eidtname: "",
      eidtcoupon_desc: "",
      eidtoriginal_price: "",
      eidtprice: "",
      eidtvalid_date: "30",
      eidtvalid_date_desc: "购买后30天有效",
      eidtuse_rule_desc: "",
      eidtdealgroup_id: "",
      // 选择门店
      eidtselects: {
        simple: "",
        languages: [],
      },
      // 团购抵扣套餐
      eidtpackageSelects: {
        simple: "",
        languages: [],
      },
      // 包间类型
      eidtroomSelects: {
        simple: "",
        languages: [
          { value: 0, label: "小包" },
          { value: 1, label: "中包" },
          { value: 2, label: "大包" },
          { value: 3, label: "任意包" },
        ],
      },
      // 购买类型
      eidtpayTypeSelects: {
        simple: "",
        languages: [
          { value: 0, label: "小程序" },
          { value: 1, label: "美团" },
          { value: 2, label: "抖音" },
        ],
      },
      // 团购券使用类型
      eidttypeSelects: {
        simple: "",
        languages: [
          { value: 0, label: "任意时间" },
          { value: 1, label: "工作日(全时段)" },
          { value: 2, label: "周末(全时段)" },
          { value: 3, label: "工作日(闲时:08:00~18:00)" },
          { value: 4, label: "工作日(通宵:00:00~10:00)" },
          { value: 5, label: "周末(通宵:00:00~10:00)" },
        ],
      },

      // storeId:"",
      // room_type:"",
      // package_id:"",
      // pay_type:"",
      // type:"",
    };
  },
  methods: {
    // 单选框选择事件
    handleSelectsChange(value) {
      // console.log("当前选择的值:", value);
      localStorage.setItem("tuanGouSelectStoreId", value);
      this.handleCurrentChange();
    },
    onBlur() {
      // 当输入框失去焦点时，改变 blurredSearchQuery 的值
      this.blurredSearchQuery = this.searchQuery;
    },

    // 编辑优惠券
    editTuan(tuanGouId) {
      http
        .post(
          "/v1/admin/adminGetTuanGouInfo",
          {
            tuanGouId: tuanGouId,
          },
          { headers: { adminToken: localStorage.getItem("adminToken") } }
        )
        .then((res) => {
          if (res.data.code != 200) {
            this.$notify({
              type: "danger",
              message: res.data.message,
              timeout: 2000,
              icon: "tim-icons icon-bell-55",
            });
            return;
          }

          let siteDataArr = [];
          res.data.data.storeInfo.forEach((element) => {
            siteDataArr.push({
              value: element.id,
              label: element.name,
            });
          });

          let allPackageDataArr = [];
          res.data.data.allPackage.forEach((element) => {
            allPackageDataArr.push({
              value: element.id,
              label: element.desc,
            });
          });

          this.eidtselects.languages = siteDataArr;
          this.eidtpackageSelects.languages = allPackageDataArr;

          this.eidtname = res.data.data.tuanGouInfo.name;
          this.eidtselects.simple = res.data.data.tuanGouInfo.store_id;
          this.eidtroomSelects.simple = res.data.data.tuanGouInfo.room_type;
          this.eidtpackageSelects.simple = res.data.data.tuanGouInfo.package_id;
          this.eidtpayTypeSelects.simple = res.data.data.tuanGouInfo.pay_type;
          this.eidttypeSelects.simple = res.data.data.tuanGouInfo.type;
          this.eidtcoupon_desc = res.data.data.tuanGouInfo.coupon_desc;
          this.eidtoriginal_price = res.data.data.tuanGouInfo.original_price;
          this.eidtprice = res.data.data.tuanGouInfo.price;
          this.eidtvalid_date = res.data.data.tuanGouInfo.valid_date;
          this.eidtvalid_date_desc = res.data.data.tuanGouInfo.valid_date_desc;
          this.eidtuse_rule_desc = res.data.data.tuanGouInfo.use_rule_desc;
          this.eidtdealgroup_id = res.data.data.tuanGouInfo.dealgroup_id;
        })
        .catch((error) => {
          console.error("发生错误:", error);
        });
      this.modals1 = true;
      this.tuanGouId = tuanGouId;
    },
    // 执行编辑优惠券
    doEditIntegral() {
      http
        .post(
          "/v1/admin/updateTuanGou",
          {
            tuanGouId: this.tuanGouId,
            name: this.eidtname,
            storeId: this.eidtselects.simple,
            roomType: this.eidtroomSelects.simple,
            packageId: this.eidtpackageSelects.simple,
            pay_type: this.eidtpayTypeSelects.simple,
            useType: this.eidttypeSelects.simple,
            coupon_desc: this.eidtcoupon_desc,
            original_price: this.eidtoriginal_price,
            price: this.eidtprice,
            valid_date: this.eidtvalid_date,
            valid_date_desc: this.eidtvalid_date_desc,
            use_rule_desc: this.eidtuse_rule_desc,
            dealgroup_id: this.eidtdealgroup_id,
          },
          { headers: { adminToken: localStorage.getItem("adminToken") } }
        )
        .then((res) => {
          if (res.data.code != 200) {
            Swal.fire({
              icon: "error",
              title: "编辑失败",
              text: res.data.message,
              customClass: {
                confirmButton: "btn btn-success btn-fill",
              },
              buttonsStyling: false,
            });
            return;
          }
          this.handleCurrentChange();
          Swal.fire({
            icon: "success",
            title: "编辑成功",
            text: res.data.message,
            customClass: {
              confirmButton: "btn btn-success btn-fill",
            },
            buttonsStyling: false,
          });

          this.modals1 = false;
        })
        .catch((error) => {
          console.error("发生错误:", error);
          // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
        });
    },
    // 更改团购券状态
    changeTuanGouState(id) {
      http
        .post(
          "/v1/admin/changeTuanGouState",
          {
            tuanGouId: id,
          },
          { headers: { adminToken: localStorage.getItem("adminToken") } }
        )
        .then((res) => {
          if (res.data.code != 200) {
            Swal.fire({
              icon: "error",
              title: "更改失败",
              text: res.data.message,
              customClass: {
                confirmButton: "btn btn-success btn-fill",
              },
              buttonsStyling: false,
            });
            return;
          }
          this.handleCurrentChange();
          Swal.fire({
            icon: "success",
            title: "更改成功",
            text: res.data.message,
            customClass: {
              confirmButton: "btn btn-success btn-fill",
            },
            buttonsStyling: false,
          });
        })
        .catch((error) => {
          console.error("发生错误:", error);
          // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
        });
    },
    // 执行添加团购券
    doAddTuanGou() {
      http
        .post(
          "/v1/admin/addTuanGou",
          {
            name: this.name,
            storeId: this.selects.simple,
            roomType: this.roomSelects.simple,
            packageId: this.packageSelects.simple,
            pay_type: this.payTypeSelects.simple,
            useType: this.typeSelects.simple,
            coupon_desc: this.coupon_desc,
            original_price: this.original_price,
            price: this.price,
            valid_date: this.valid_date,
            valid_date_desc: this.valid_date_desc,
            use_rule_desc: this.use_rule_desc,
            dealgroup_id: this.dealgroup_id,
          },
          { headers: { adminToken: localStorage.getItem("adminToken") } }
        )
        .then((res) => {
          if (res.data.code != 200) {
            Swal.fire({
              icon: "error",
              title: "添加失败",
              text: res.data.message,
              customClass: {
                confirmButton: "btn btn-success btn-fill",
              },
              buttonsStyling: false,
            });
            return;
          }
          this.handleCurrentChange();
          Swal.fire({
            icon: "success",
            title: "添加成功",
            text: res.data.message,
            customClass: {
              confirmButton: "btn btn-success btn-fill",
            },
            buttonsStyling: false,
          });

          this.modals = false;
        })
        .catch((error) => {
          console.error("发生错误:", error);
          // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
        });
    },
    // 添加团购券
    addTuanGou() {
      this.selects.simple = "";
      http
        .get("/v1/admin/addGetAllStore", {
          headers: { adminToken: localStorage.getItem("adminToken") },
        })
        .then((res) => {
          if (res.data.code != 200) {
            this.$notify({
              type: "danger",
              message: res.data.message,
              timeout: 2000,
              icon: "tim-icons icon-bell-55",
            });
            return;
          }
          let siteDataArr = [];
          res.data.data.storeInfo.forEach((element) => {
            siteDataArr.push({
              value: element.id,
              label: element.name,
            });
          });

          let allPackageDataArr = [];
          res.data.data.allPackage.forEach((element) => {
            allPackageDataArr.push({
              value: element.id,
              label: element.desc,
            });
          });

          this.selects.languages = siteDataArr;
          this.packageSelects.languages = allPackageDataArr;
          this.modals = true;
        })
        .catch((error) => {
          console.error("发生错误:", error);
          // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
        });
    },
    hangleCancel(id) {
      http
        .post(
          "/v1/admin/cancelIntegral",
          {
            integralId: id,
          },
          { headers: { adminToken: localStorage.getItem("adminToken") } }
        )
        .then((res) => {
          if (res.data.code != 200) {
            Swal.fire({
              icon: "error",
              title: "停用优惠券失败",
              text: res.data.message,
              customClass: {
                confirmButton: "btn btn-success btn-fill",
              },
              buttonsStyling: false,
            });
            return;
          }
          this.handleCurrentChange();
          Swal.fire({
            icon: "success",
            title: "停用优惠券成功",
            text: res.data.message,
            customClass: {
              confirmButton: "btn btn-success btn-fill",
            },
            buttonsStyling: false,
          });
        })
        .catch((error) => {
          console.error("发生错误:", error);
          // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
        });
    },
    // 获取所有用户信息
    handleCurrentChange() {
      let tuanGouSelectStoreId = localStorage.getItem("tuanGouSelectStoreId");
      if (tuanGouSelectStoreId) {
        this.tuanGouSelects.simple = tuanGouSelectStoreId;
      } else {
        this.tuanGouSelects.simple = "0";
        localStorage.setItem("tuanGouSelectStoreId", "0");
        tuanGouSelectStoreId = "0"
      }

      http
        .post(
          "/v1/admin/getAllTuanGou",
          {
            currentPage: this.pagination.currentPage,
            perPage: this.pagination.perPage,
            searchQuery: this.searchQuery,
            storeId:tuanGouSelectStoreId,
          },
          { headers: { adminToken: localStorage.getItem("adminToken") } }
        )
        .then((res) => {
          if (res.data.code != 200) {
            this.$notify({
              type: "danger",
              message: res.data.message,
              timeout: 2000,
              icon: "tim-icons icon-bell-55",
            });
            return;
          }

          this.pagination.total = res.data.data.total;
          this.tableData = res.data.data.tuanGou;

          this.tuanGouSelects.languages = [{ value: "0", label: "全部门店" }];
          res.data.data.allStore.forEach((element) => {
            let storeData = { value: String(element.id), label: element.name };
            this.tuanGouSelects.languages.push(storeData);
          });
        })
        .catch((error) => {
          console.error("发生错误:", error);
        });
    },
    timeFormatter(row, column, cellValue) {
      // 在这里处理你的数据
      // row 是整个行数据
      // column 是列数据
      // cellValue 是单元格的值
      // 例如你想将名字转为大写形式：
      if (cellValue == "0001-01-01T00:00:00Z") {
        return "";
      } else {
        cellValue = changeDateFormat(cellValue);
        return cellValue;
      }
    },
  },
  mounted() {
    this.handleCurrentChange();
  },
  watch: {
    blurredSearchQuery(newVal, oldVal) {
      // 监控搜索
      if (newVal == "") {
        this.handleCurrentChange();
        return;
      }
      this.handleCurrentChange();
    },
    "pagination.currentPage"(newPage, oldPage) {
      // 监控翻页
      // 这里进行你的数据请求等操作
      this.handleCurrentChange();
    },
    "pagination.perPage"(newPage, oldPage) {
      // 监控翻页
      // 这里进行你的数据请求等操作
      this.handleCurrentChange();
    },
  },
};
</script>
<style scoped lang="scss" >
.pagination-select,
.search-input {
  width: 200px;
}
::v-deep .modal-dialog {
  //max-width: 2200px !important;
  margin: 5.2rem auto !important;
}
</style>
