<template>




  <div class="container">
    <div class="vertical-center-wrapper">
      <div class="col-lg-4 col-md-6">
        <!-- <ValidationObserver v-slot="{ handleSubmit }"> -->
        <!-- <form @submit.prevent="handleSubmit(submit)"> -->
        <card class="card-login card-white">
          <template slot="header">
            <img src="img/card-primary.png" alt="" />
            <h1 class="card-title">Log in</h1>
          </template>

          <div>
            <ValidationProvider
              name="username"
              rules="required|min:5"
              v-slot="{ passed, failed, errors }"
            >
              <base-input
                required
                v-model="username"
                type="text"
                placeholder="请输入账号"
                addon-left-icon="tim-icons icon-email-85"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
              >
              </base-input>
            </ValidationProvider>

            <ValidationProvider
              name="password"
              rules="required|min:5"
              v-slot="{ passed, failed, errors }"
            >
              <base-input
                required
                v-model="password"
                placeholder="请输入密码"
                addon-left-icon="tim-icons icon-lock-circle"
                type="password"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
              >
              </base-input>
            </ValidationProvider>
          </div>

          <div slot="footer">
            <base-button
              native-type="submit"
              type="primary"
              class="mb-3"
              size="lg"
              block
              @click="handleSubmit"
            >
              登录
            </base-button>
          </div>
        </card>
        <!-- </form> -->
        <!-- </ValidationObserver> -->
      </div>
    </div>
  </div>







</template>
<script>
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";
import { http } from "../../util/http.js";
extend("email", email);
extend("min", min);
extend("required", required);

export default {
  data() {
    return {
      username: "",
      password: "",
      subscribe: true,
    };
  },
  methods: {
    handleSubmit() {
      if (this.username == "" || this.password == "") {
         this.$notify({type: 'danger', message: '账号密码不能为空',timeout:3000,icon:"tim-icons icon-bell-55"})
        return;
      }
      http
        // .post("/v1/admin/adminLogin", {
        .post("/v1/agent/agentLogin", {
          userName: this.username,
          passWord: this.password,
        })
        .then((res) => {
          if (res.data.code != 200) {
             this.$notify({type: 'danger', message: res.data.message,timeout:3000,icon:"tim-icons icon-bell-55"})
            return;
          }
         this.$notify({type: 'success', message: res.data.message,timeout:3000,icon:"tim-icons icon-bell-55"})
          localStorage.setItem("agentToken", res.data.data);
          this.$router.push("/");
        })
        .catch((error) => {
          console.error("发生错误:", error);
          // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
        });
    },
  },
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
.auth-page {
  height: 100%;
  background: url("../../assets/WechatIMG129.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* 使用视口高度确保容器至少和视口一样高 */
}

.vertical-center-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

</style>
