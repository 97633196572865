<template>
  <div class="row">
    <card
      type="tasks"
      class="col-sm"
      style="height: auto; padding-bottom: 30px"
    >
      <template slot="header">
        <div style="">
          <div class="row">
            <div class="">
              <h4 class="title d-inline">轮播图管理</h4>
            </div>
            <div class="ml-auto">
              <div>
                <base-button
                  style="margin-right: 10px"
                  size="sm"
                  type="danger"
                  @click="addBanner"
                  >添加轮播图</base-button
                >
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 20px">
          <base-table :data="tableData" :columns="columns">
            <template slot="columns">
              <th>id</th>
              <th>
                轮播图
              </th>
              <th>轮播图状态</th>
              <th>添加时间</th>
              <th class="">删除</th>
            </template>
            <template slot-scope="{ row }">
              <td>{{ row.id }}</td>
              <td><img style="width: 10rem" :src="getImg(row.banner_img)" alt="" /></td>
              <td>
                <base-button
                  v-if="row.state == 0"
                  style="margin-left: 6px"
                  size="sm"
                  round
                  icon
                  type="danger"
                  @click="changeBannerState(row.id)"
                  ><i class="tim-icons icon-button-pause"></i
                ></base-button>
                <base-button
                  v-if="row.state == 1"
                  style="margin-left: 6px"
                  size="sm"
                  round
                  icon
                  type="success"
                  @click="changeBannerState(row.id)"
                  ><i class="tim-icons icon-triangle-right-17"></i
                ></base-button>

                <!-- <badge type="success" v-if="row.state==1">正常</badge>
                <badge type="danger" v-if="row.state==0">停用</badge> -->
              </td>

              <td>{{ changeDateFormat(row.add_time) }}</td>
              <td class="">
                <base-button
                  type="danger"
                  style="margin-left: 6px"
                  size="sm"
                  icon
                  @click="deleteBanner(row.id)"
                >
                  <i
                    style="color: #ffffff"
                    class="tim-icons icon-simple-remove"
                  ></i>
                </base-button>
              </td>
            </template>
          </base-table>
        </div>
      </template>

      <div>
        <modal
          modelWidth="650px"
          :show.sync="modals1"
          style="z-index: 1051"
          headerClasses="justify-content-center"
        >
          <h4 slot="header" class="title title-up">添加轮播图</h4>
          <div style="margin-top: 22px">
            <image-upload
              ref="addBannerImageRemoveFile"
              @change="onImageBanner"
              select-text="上传轮播图图片"
            />
          </div>
          <div style="margin-top: 18px"></div>
          <template slot="footer">
            <base-button @click="doaddBanner">确定</base-button>
            <base-button type="danger" @click.native="modals1 = false"
              >取消
            </base-button>
          </template>
        </modal>
      </div>
    </card>
  </div>
</template>
<script>
import BaseTable from "@/components/BaseTable";
import Badge from "@/components/Badge";
import { http,imageLocations } from "../../util/http.js";
import Modal from "@/components/Modal";
import { Select, Option } from "element-ui";
import Swal from "sweetalert2";
import { ImageUpload } from "src/components";
import { changeDateFormat, checkTime } from "../../util/utils.js";

export default {
  components: {
    BaseTable,
    // Badge,
    Modal,
    ImageUpload,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      imageLocations,
      changeDateFormat,
      packageName: "",
      packageDuration: "",
      packagePrice: "",

      editPackageName: "",
      editPackageDuration: "",
      editPackagePrice: "",

      packageId: "",
      columns: ["id", "banner_img", "state", "add_time"],
      tableData: [],
      packageSIte: [],
      updatePackageList: [],
      modals: false,
      modals1: false,
      selects1: {
        languages: [],
        multiple: "",
      },
      selectedPackages: [],
      packageCounts: {},

      imageBanner: {
        regular: null,
      },
    };
  },
  mounted() {
    this.getPackage();
  },
  methods: {
    // 轮播图上传
    onImageBanner(file) {
      this.imageBanner.regular = file;
    },
    // 获取所有套餐
    getPackage() {
      http
        .get("/v1/admin/getAllBanner", {
          headers: { adminToken: localStorage.getItem("adminToken") },
        })
        .then((res) => {
          if (res.data.code != 200) {
            this.$notify({
              type: "danger",
              message: res.data.message,
              timeout: 2000,
              icon: "tim-icons icon-bell-55",
            });
            return;
          }
          this.tableData = res.data.data.banner;
        })
        .catch((error) => {
          console.error("发生错误:", error);
          // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
        });
    },
    // 添加轮播图
    addBanner() {
      this.modals1 = true;
    },
    getImg(imgPath) {
      return imageLocations + imgPath;
    },
    // 执行添加轮播图
    doaddBanner() {
      let formData = new FormData();
      formData.append("image", this.imageBanner.regular);
      http
        .post("/v1/admin/addHomeBanner", formData, {
          headers: { adminToken: localStorage.getItem("adminToken") },
        })
        .then((res) => {
          if (res.data.code != 200) {
            Swal.fire({
              icon: "error",
              title: "添加失败",
              text: res.data.message,
              customClass: {
                confirmButton: "btn btn-success btn-fill",
              },
              buttonsStyling: false,
            });
            return;
          }
          this.getPackage();
          Swal.fire({
            icon: "success",
            title: "添加成功",
            text: res.data.message,
            customClass: {
              confirmButton: "btn btn-success btn-fill",
            },
            buttonsStyling: false,
          });
          this.modals1 = false;

          this.$refs.addBannerImageRemoveFile.removeFile();
        })
        .catch((error) => {
          console.error("发生错误:", error);
          // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
        });
    },
    // 更改轮播图状态
    changeBannerState(id) {
      http
        .post(
          "/v1/admin/changeBannerState",
          {
            bannerId: id,
          },
          { headers: { adminToken: localStorage.getItem("adminToken") } }
        )
        .then((res) => {
          if (res.data.code != 200) {
            Swal.fire({
              icon: "error",
              title: "更改失败",
              text: res.data.message,
              customClass: {
                confirmButton: "btn btn-success btn-fill",
              },
              buttonsStyling: false,
            });
            return;
          }
          Swal.fire({
            icon: "success",
            html: "更改成功",
            customClass: {
              confirmButton: "btn btn-success btn-fill",
            },
            buttonsStyling: false,
          });

          this.getPackage();
        })
        .catch((error) => {
          console.error("发生错误:", error);
          // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
        });
    },
    deleteBanner(bannerId){
      Swal.fire({
        title: "删除轮播图",
        text: `确定要删除轮播图吗?`,
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-danger btn-fill",
        },
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          http
            .post(
              "/v1/admin/deleteHomeBanner",
              {
                bannerId: bannerId,
              },
              { headers: { adminToken: localStorage.getItem("adminToken") } }
            )
            .then((res) => {
              if (res.data.code != 200) {
                Swal.fire({
                  icon: "error",
                  title: "操作失败",
                  text: res.data.message,
                  customClass: {
                    confirmButton: "btn btn-success btn-fill",
                  },
                  buttonsStyling: false,
                });
                return;
              }
              this.getPackage();

              Swal.fire({
                icon: "success",
                title: "操作成功",
                text: res.data.message,
                customClass: {
                  confirmButton: "btn btn-success btn-fill",
                },
                buttonsStyling: false,
              });

            })
            .catch((error) => {
              console.error("发生错误:", error);
              // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
            });
        }
      });
    },
  },
  watch: {
    selectedPackages(newVal, oldVal) {
      oldVal.forEach((pkg) => {
        if (!newVal.includes(pkg)) {
          this.$delete(this.packageCounts, pkg);
        }
      });
      newVal.forEach((pkg) => {
        if (!this.packageCounts[pkg]) {
          this.$set(this.packageCounts, pkg, 1);
        }
      });
    },
  },
};
</script>
<style scoped>
.modal-content {
  width: 800px;
}
</style>



 